<template>
  <BlockContainer>
    <div v-if="!!data" class="flex items-center flex-col">
      <div class="w-full flex flex-col px-10 md:px-20 pt-5 md:pt-10 pb-8">
        <div v-if="!formStatus" class="flex flex-col gap-4 text-center">
          <h2 class="uppercase text-lg">
            {{ displayTitle }}
          </h2>
          <CoreRichText
            class="text-center intro-text"
            :document="displayIntroText"
          />
        </div>
        <div v-else class="text-center">
          <p>Thank you for getting in touch. We will be in contact soon.</p>
        </div>
      </div>
      <div class="max-w-screen-lg px-4 md:px-0 pb-10 md:pb-20">
        <form
          v-if="!formStatus"
          class="flex flex-col gap-4"
          action=""
          autocomplete="off"
          @submit.prevent="handleContactForm"
        >
          <div class="flex gap-4">
            <div class="flex-grow">
              <label for="first_name">First Name</label>
              <input
                id="first_name"
                name="first_name"
                type="text"
                v-model="formData.firstName"
                required
              />
            </div>
            <div class="flex-grow">
              <label for="last_name">Last Name</label>
              <input
                id="last_name"
                name="last_name"
                type="text"
                v-model="formData.lastName"
                required
              />
            </div>
          </div>
          <div>
            <label for="email">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              v-model="formData.email"
              required
            />
          </div>
          <div>
            <label for="phone">Phone Number</label>
            <input
              id="phone"
              name="phone"
              type="tel"
              v-model="formData.phone"
              required
            />
          </div>
          <div>
            <label for="description"
              >A brief description of what you’re looking for or any specific
              question:
            </label>
            <textarea
              name="description"
              id="description"
              rows="3"
              v-model="formData.description"
              required
            />
          </div>
          <div>
            <label for="consultation"
              >Would you like to request a consultation? Please suggest dates
              and times:
            </label>
            <textarea
              name="consultation"
              id="consultation"
              rows="3"
              v-model="formData.consultation"
              required
            />
          </div>
          <div>
            <fieldset class="flex gap-4">
              <legend class="mb-2">Preferred Contact Method:</legend>
              <div class="flex gap-2">
                <input
                  type="checkbox"
                  id="contact_method_phone"
                  name="contact_method"
                  value="phone"
                  v-model="formData.contactMethodPhone"
                />
                <label for="contact_method_phone">Phone</label>
              </div>
              <div class="flex gap-2">
                <input
                  type="checkbox"
                  id="contact_method_email"
                  name="contact_method"
                  value="email"
                  v-model="formData.contactMethodEmail"
                />
                <label for="contact_method_email">Email</label>
              </div>
            </fieldset>
          </div>
          <input type="submit" value="Submit" />
        </form>
      </div>
    </div>
  </BlockContainer>
</template>

<script>
import { BlockContainer } from '@c/Block';
import { getContact } from '@io';
import axios from 'axios';

export default {
  name: 'Contact',

  components: {
    BlockContainer,
  },

  data: () => ({
    data: null,
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      description: '',
      consultation: '',
      contactMethodPhone: false,
      contactMethodEmail: false,
    },
    formStatus: false,
  }),

  computed: {
    displayIntroText() {
      return this.data['introText'];
    },

    displayTitle() {
      return this.data['title'];
    },
  },

  async created() {
    this.data = await getContact();
  },

  methods: {
    async handleContactForm() {
      try {
        const response = await axios.post('https://formspree.io/f/mdkoveno', {
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          email: this.formData.email,
          phone: this.formData.phone,
          description: this.formData.description,
          consultation: this.formData.consultation,
          contactMethodPhone: this.formData.contactMethodPhone,
          contactMethodEmail: this.formData.contactMethodEmail,
        });

        if (response.status === 200) this.formStatus = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.intro-text ::v-deep p {
  @apply mb-4;
}

.intro-text ::v-deep p:last-child {
  @apply mb-0;
}

form input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  outline: none;
  transition: 250ms ease-out all;
}

form input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
textarea:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

form input[type='submit'] {
  background-color: rgba(255, 255, 255, 1);
  color: black;
  border: 1px solid rgba(255, 255, 255, 1);
  transition: 250ms all ease-out;
  padding: 0.5rem 0;
  cursor: pointer;
}

form input[type='submit']:hover {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 1);
}
</style>

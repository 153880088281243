<template>
  <BlockContainer>
    <div v-if="!!data" class="flex flex-col flex-grow">
      <BlockHero :image="data.hero" />
      <CoreBar class="flex justify-center items-center text-lg">
        <span
          class="mx-4 cursor-pointer word-spacing"
          :class="{ underline: page === 'profile' }"
          @click="page = 'profile'"
          >ABOUT ME : PROFILE</span
        >
        <span
          class="mx-4 cursor-pointer word-spacing"
          :class="{ underline: page === 'collecting' }"
          @click="page = 'collecting'"
          >ABOUT YOU : COLLECTING</span
        >
      </CoreBar>
      <BlockGradient :alignment="alignment" class="flex-grow">
        <div class="p-10 md:p-20">
          <CoreRichText class="text-center h6-caption" :document="data[page]" />
          <div
            v-show="page === 'collecting'"
            class="flex flex-wrap justify-center items-center"
          >
            <a
              class="flex flex-col items-center mx-2 my-2"
              v-for="(download, i) in data.downloads"
              :key="i"
              :href="download.fields.asset.fields.file.url"
              target="_blank"
            >
              <CoreImage :image="download.fields.image" imgClass="h-24" />
              <p>{{ download.fields.title }}</p>
            </a>
          </div>
        </div>
      </BlockGradient>
    </div>
  </BlockContainer>
</template>

<script>
import { getAbout } from "@io";
import { BlockContainer, BlockGradient, BlockHero } from "@c/Block";

export default {
  name: "About",

  components: {
    BlockContainer,
    BlockGradient,
    BlockHero
  },

  data: () => ({
    data: null,
    page: "profile"
  }),

  computed: {
    alignment() {
      const alignment = {
        profile: "left",
        collecting: "right"
      };

      return alignment[this.page];
    }
  },

  async created() {
    this.data = await getAbout();
  }
};
</script>

<style scoped>
.h6-caption ::v-deep h6 {
  font-size: 0.8rem;
}
.word-spacing {
  word-spacing: 4px;
}
</style>
